<section id="referafriend" class="pt-50">
    <div class="container-fluid referAFrindBG">
        <div class="referAFriendBG">
            <div class="container pt-70">
                <div class="section-title">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="pageHeaderRefer">
                                <img src="../assets/img/refer-a-friend-heading.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="referRunningContent">
                                <p class="pl5">Note: If you are an existing customer, please <a
                                        href="/mytax/#/login"><u>login</u></a> to your account to
                                    'Refer' your
                                    Friend(s). This enables you to keep record of all your Referred Friends</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 plr20">
                        <div class="stepBoxImage"><img src="../assets/img/refer-1.png"></div>
                        <div class="stepBoxContent">
                            You <b>will receive 10%</b> of the Fee paid by your Friend as Referral Bonus
                        </div>
                    </div>
                    <div class="col-sm-4 plr20">
                        <div class="stepBoxImage"><img src="../assets/img/refer-2.png"></div>
                        <div class="stepBoxContent">
                            You can participate in our 'Top Three' Referral Program which can make you <b>WIN FREE TRIP
                                TO INDIA,
                                FREE APPLEIPHONE and FREE Apple Ipad Air.</b> Apart from this, there are many more
                            prizes to be
                            won.
                        </div>
                    </div>
                    <div class="col-sm-4 plr20">
                        <div class="stepBoxImage"><img src="../assets/img/refer-3.png"></div>
                        <div class="stepBoxContent">
                            You can <b>become eligible to receive loyalty discount</b> when you file your Taxes next
                            year
                        </div>
                    </div>
                    <div class="col-12 mt25 fs18 p0 text-center">
                        <div class="zInexTop"><a href="#referAFriendSubmitForm" class="custbtn"><b>Click here</b></a> to
                            refer your friends... </div><br />
                        <div id="referAFriendSubmitForm" class="zInexTop">For more information on our Referral
                            Policy, Please <a class="hoveralink" (click)="openFullReferralPolicy()"><b>click
                                    here</b></a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contaciner-fluid pt-70">
        <div class="container">
            <div class="referAFriendFormHeading">REFER a FRIEND!</div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-form">
                        <form [formGroup]="dataForm" (submit)="onDataSubmit()" class="needs-validation">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label>Your Name</label>
                                        <input type="text" formControlName="yourName" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.yourName.invalid, 'is-valid': submitted && !f.yourName.invalid }">
                                        <small class="form-text text-danger"
                                            *ngIf="submitted && (f.yourName.invalid || f.yourName.errors?.required)">Please
                                            Enter Your Name</small>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label>Your Telephone Number</label>
                                        <input type="text" formControlName="yourPhone" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.yourPhone.invalid, 'is-valid': submitted && !f.yourPhone.invalid }">
                                        <small class="form-text text-danger"
                                            *ngIf="submitted && (f.yourPhone.invalid || f.yourPhone.errors?.required)">Please
                                            Enter Your Telephone Number</small>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label>Your Email Id</label>
                                        <input type="email" formControlName="yourEmail" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.yourEmail.invalid, 'is-valid': submitted && !f.yourEmail.invalid }">
                                        <small class="form-text text-danger"
                                            *ngIf="submitted && (f.yourEmail.invalid || f.yourEmail.errors?.required)">Please
                                            Enter Your Email Id</small>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label>Name of Your Friend *</label>
                                        <input type="text" formControlName="friendName" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.friendName.invalid, 'is-valid': submitted && !f.friendName.invalid }">
                                        <small class="form-text text-danger"
                                            *ngIf="submitted && (f.friendName.invalid || f.friendName.errors?.required)">Please
                                            Enter Your Friend Name</small>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label>Friend's Telephone Number *</label>
                                        <input type="text" formControlName="friendPhone" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.friendPhone.invalid, 'is-valid': submitted && !f.friendPhone.invalid }">
                                        <small class="form-text text-danger"
                                            *ngIf="submitted && (f.friendPhone.invalid || f.friendPhone.errors?.required)">Please
                                            Enter Friend Telephone Number</small>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label>Friend's Email id *</label>
                                        <input type="email" formControlName="friendEmail" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.friendEmail.invalid, 'is-valid': submitted && !f.friendEmail.invalid }">
                                        <small class="form-text text-danger"
                                            *ngIf="submitted && (f.friendEmail.invalid || f.friendEmail.errors?.required)">Please
                                            Enter Friend Email id</small>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="btn btn-primary">SUBMIT</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="winnersPan">
                        <h1>Hearty Congratulations to all our <span>TY2023 Prize Winners</span> - Here are their
                            responses</h1>
                        <p>&nbsp;</p>
                        <p>
                            <i class="fa fa-hand-point-right"></i>
                            I thank you and your team for making the tax filing process easier for me. You guys helped
                            in getting the ITIN for my kid, even though we were in India. Though there was a delay in
                            the refund because of filing in April, your team helped in tracking the refund status and
                            updated me regularly, I truly appreciate all the follow-up and support your team provided
                            throughout this process. Your procedure was evidently effective and efficient, which made
                            this process much easier than I anticipated. Because of the great experience, I have already
                            referred a few of my friends to your services. Thank you all for your assistance!
                        </p>
                        <p class="cst"> - Ramesh, TN</p>
                        <p><i class="fa fa-hand-point-right"></i>
                            My accolades for the excellent service you have given during the filing of my 1120 business
                            return. Right from the beginning, the process was handled with care and attention, coupled
                            with your team's valuable insights and regular updates, made me feel confident that my tax
                            return was filed accurately and on time. Thank you also for considering me for the referral
                            reward – it's a good gesture by your team, and I truly appreciate it!
                        </p>
                        <p class="cst"> – Jyothi, WA</p>
                        <p><i class="fa fa-hand-point-right"></i>
                            I am grateful for your team's efforts and the personal attention you gave to ensure
                            everything was in order. It's rare to find such a reliable and constant support system, and
                            I will certainly be recommending your firm to my business associates in the future.
                        </p>
                        <p class="cst"> – Vivek, IL</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="winnersPan">
                        <h1>Hearty Congratulations to all our <span>TY2022 Prize Winners</span> - Here are their
                            responses</h1>
                        <p>&nbsp;</p>
                        <p>
                            <i class="fa fa-hand-point-right"></i>
                            I adore your staff for constantly bearing with me to file the complex tiresome partnership
                            business return. Your to and fro communication with good inputs and updates from you made me
                            feel that the tax return filed is accurate and I will refer my other business friends to
                            you. Thank you for considering me for the referral reward.
                        </p>
                        <p class="cst"> - Hemant, TX</p>
                        <p><i class="fa fa-hand-point-right"></i>
                            Your ITIN procedure helped me to get the ITIN for my son easily even when we were in India.
                            Though it took some reasonable time glad to receive the ITIN for my kid. Thanks to your team
                            for following up in this regard. Appreciate it. Hence referred few of my colleagues to you.
                        </p>
                        <p class="cst"> – Gopi T, Bengaluru</p>
                        <p><i class="fa fa-hand-point-right"></i>
                            I have been filing with you for more than 10 years, I am gald to give this feedback to you.
                            Your staff have been helpful in filing timely taxes as usual with ease. This year I would
                            like to incorporate a business will opt for this service soon. Thank you.
                        </p>
                        <p class="cst"> – Vamsi Krishna, WA</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="winnersPan">
                        <h1>Hearty Congratulations to all our <span>TY2020 Prize Winners</span> - Here are their
                            responses</h1>
                        <p>&nbsp;</p>
                        <p>
                            <i class="fa fa-hand-point-right"></i>
                            The way you guys helped with my complex tax return involving Crypto Currency calculations
                            and S-Corp filing is worth a praise. Thanks for your gift. I will surely refer more friends
                            this time.
                        </p>
                        <p class="cst"> - Aravind, Pensacola, FL</p>
                        <p><i class="fa fa-hand-point-right"></i>
                            Thanking your employee for supporting in applying ITIN for my dependent. And IRS query was
                            addressed well in time. Thanks for giving that peace of mind with your prompt response.
                        </p>
                        <p class="cst"> – Uttam, Mumbai</p>
                        <p><i class="fa fa-hand-point-right"></i>
                            Hey, thanks for the gift. I should tell you that your knowledgeable staff are the key to
                            your company. Keep up the good work and your service is commendable.
                        </p>
                        <p class="cst"> – Vaibhav, Bellevue, WA</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="winnersPan">
                        <h1>Hearty Congratulations to all our <span>TY2019 Prize Winners</span> - Here are their
                            responses</h1>
                        <p>&nbsp;</p>
                        <p>
                            <i class="fa fa-hand-point-right"></i>
                            Thanks a lot for your prize which helped me in travelling during these tough times of
                            Pandemic. You guys have been fabulous in dealing with my complex taxes. Thanks for your
                            diligence in your service and support.
                        </p>
                        <p class="cst"> - Suriya Mishra, TX</p>
                        <p><i class="fa fa-hand-point-right"></i>
                            I see that your knowledgeable staff is your strength to your business. I am indeed thankful
                            to your gift and will continue referring more to you.
                        </p>
                        <p class="cst"> – Anirudh Sharma, CA</p>
                        <p><i class="fa fa-hand-point-right"></i>
                            Keep up the good work you have been doing. The promptness and accuracy in the services
                            provided by you made me refer many of my friends to you. Keep up the good work and
                            appreciate your gift.
                        </p>
                        <p class="cst"> – Ram Chaudhuri, Delhi</p>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</section>