<section id="about" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                About Us
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">About Us <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span>
    </div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <!--div class="fa-pull-right"><img src="./assets/img/aboutimage_v1.png"></div-->
                <p>Universal Financials, an initiative from MetaFin Consulting LLC, based in New Jersey, USA, was
                    founded by a team of seasoned professionals, including CPAs and EAs, with a singular goal - to
                    deliver the highest quality financial services to our valued clients. The firm was established by a
                    group of visionaries who sought to create a lasting impact in the financial world, serving clients
                    in both the United States of America and India.</p>

                <p>While many companies offer financial services that meet basic client needs, Universal Financials goes
                    beyond simply fulfilling requests. We recognize that every financial transaction involves the
                    careful use of hard-earned money, which is why we emphasize the importance of thoughtful
                    decision-making before committing to any financial action.</p>             

                <p>
                    Take, for example, the process of filing a tax return. Many service providers prepare and file
                    returns based on the client's instructions, leading to customer satisfaction once the return is
                    completed. However, this approach may not always be the most efficient or beneficial. A tax return
                    could be filed in several ways, and while it may meet the immediate needs of the client, there are
                    often opportunities for more effective tax planning that could result in a larger refund or a
                    reduced tax liability.</p>

                <p>At Universal Financials, we don’t just meet financial needs—we enhance the financial outcomes for our
                    clients by applying strategic, thoughtful planning. We treat each client with the utmost care, as if
                    they were our first, ensuring that every service we provide is tailored to maximize value. Our
                    slogan, "Avail our Service and Feel the Difference," reflects our commitment to delivering
                    exceptional service.</p>

                <p>The strength of Universal Financials lies in our team of qualified and highly trained professionals,
                    whose dedication to diligence and expertise ensures that we always address our clients' needs with
                    precision. Through years of experience and extensive research, we offer our clients insights that go
                    beyond standard solutions, bringing them the benefits of well-informed financial guidance.</p>
            </div>
        </div>
    </div>
</section>