<footer class="footer-area">
    <!--div class="container">
        <h3><a routerLink="/"><span>X</span>avro</a></h3>
        <ul>
            <li><a href="#" class="fab fa-facebook-f"></a></li>
            <li><a href="#" class="fab fa-twitter"></a></li>
            <li><a href="#" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" class="fab fa-instagram"></a></li>
            <li><a href="#" class="fab fa-skype"></a></li>
        </ul>
        <p>Copyright <i class="far fa-copyright"></i>2025 All Rights Reserved.</p>
    </div-->
    <footer class="footer-area" id="footerPan123">
        <!--div class="container">
            <h3><a routerLink="/"><span>X</span>avro</a></h3>
            <ul>
                <li><a href="#" class="fab fa-facebook-f"></a></li>
                <li><a href="#" class="fab fa-twitter"></a></li>
                <li><a href="#" class="fab fa-linkedin-in"></a></li>
                <li><a href="#" class="fab fa-instagram"></a></li>
                <li><a href="#" class="fab fa-skype"></a></li>
            </ul>
            <p>Copyright <i class="far fa-copyright"></i>2025 All Rights Reserved.</p>
        </div-->
        <div class="container">
            <div class="row">
                <div class="col-xs-6 col-sm-3 col-md-2 pd-0 f-links bord">
                    <ul>
                        <li><a class="custbtn" href="#home">Home</a></li>
                        <li><a class="custbtn" href="#about">About Us</a></li>
                        <li><a class="custbtn" href="#services">Services</a></li>
                        <li><a class="custbtn" href="#referafriend">Refer a Friend</a></li>
                        <li><a class="custbtn" href="#resources">Resources</a></li>
                        <li><a class="custbtn" href="#careers">Careers</a></li>
                        <li><a class="custbtn" href="#contact">Contact Us</a></li>
                    </ul>
                </div>
                <div class="col-xs-6 col-sm-3 col-md-2 f-links"><!-- offset-md-2 -->
                    <ul class="footerMainLinks">
                        <li><a (click)="openFullDisclaimer()">Disclaimer</a></li>
                        <li><a (click)="openFullTermsConditions()">Terms &amp; Conditions</a></li>
                        <li><a (click)="openFullPrivacyPolicy()">Privacy Policy</a></li>
                        <li><a (click)="openFullReferralPolicy()">Referral Policy</a></li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 pd-0 f-links">
                    <ul class="faddressUSOffice">
                        <li class="faddress">US Office</li>
                        <li>1-626-626-7935</li>
                        <li><a href="mailto:contact@universalfinancials.com">contact@universalfinancials.com</a></li>                        
                    </ul>
                    <div class="efile mt10">
                        <img src="../assets/img/crdeit-cards.png" alt="Payment">
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 pd-0 f-address">
                    <ul class="faddressUSOffice">
                        <li class="faddress">India Office</li>
                        <li>+91-8501003000</li>
                        <li><a href="mailto:contact@universalfinancials.com">contact@universalfinancials.com</a></li>
                    </ul>
                    <div class="efile text-left">
                        <script type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=MM9GxLIBr6quOECEXxV9XkaHMxDmaq5MlKtzakchR6fEyIlI3mSYkM"></script>

                        <a href="http://www.irs.gov/" target="_blank"><img class="mobview" src="../assets/img/e_file_logo.jpg" border="0" alt=""></a>
                       &nbsp; &nbsp; <a href="https://www.paypal.com/us/cgi-bin/webscr?cmd=xpt/Marketing/general/SolutionGraphics-outside" target="_blank"><img class="mobview" src="../assets/img/paypal_logo.jpg" border="0" alt=""></a>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="col-xs-12 col-sm-12 copyrig"><p>Copyright &copy; 2010 - 2025 Universal Financials, All Rights Reserved.</p></div>
            </div>
        </div>
    </footer>

    <div class="go-top"><i class="fas fa-chevron-up"></i></div>

</footer>

<div class="go-top"><i class="fas fa-chevron-up"></i></div>

