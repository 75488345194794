<section class="welcome-area" appNoRightClick>
    <div class="modal-content custModalBoxDesing">
        <div class="modal-header">
            <button type="button" class="close" (click)="close()" ></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-7 col-xs-7">
                    <div class="welcomeToTxt">Welcome to <br/> Universal <br/> Financials...</div>
                </div>
                <div class="col-sm-4 col-xs-4">
                    <div class="modal-title-Label">
                        <span class="upto">UP TO</span>
                        <span class="persantage">10%</span>
                        <span class="upto">OFF</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">&nbsp;</div>
                <div class="col-sm-6 col-xs-6">
                    <div class="modal-body-content">
                        <a href="https://www.universalfinancials.com/mytax/#/register" class="register">Register</a> on or before <br>January 31, 2025 <br> to file your US Tax Return <br> and  avail 10% Discount <br> on total fees.
                    </div>
                </div>
                <div class="col-sm-2">&nbsp;</div>
            </div>
            <div class="row">
                <div class="col-sm-4 col-xs-4">
                    <div class="modal-footer-content">Get FREE Tax Estimates.</div>
                </div>
                <div class="col-sm-8 col-xs-8 text-right">
                    <div class="modal-footer-content">Also, <a href="https://www.universalfinancials.com/mytax/#/login" >Refer friends</a> to avail UNLIMITED Bonus.</div>
                </div>
            </div>
            <div class="row mt5">
                <div class="col text-center colorWhite">
                    <a href="https://www.universalfinancials.com/mytax/#/login" style="font-weight: bold;" class="text-success"> Applying ITIN??</a> – Get QUICK and ERROR FREE assistance
                </div>
            </div>
        </div>
    </div>
</section>
