<div class="topHeaderFixed">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-4">
                <div class="contactNumbers">
                    <ul>
                        <li><img src="./assets/img/us.png" alt=""> 1-626-626-7935</li>
                        <li><img src="./assets/img/india.png" alt=""> 91-8501003000</li>
                    </ul>
                </div>
                <div class="contactEmail">
                    <i class="fa fa-envelope"></i> <a href="mailto:contact@universalfinancials.com">contact@universalfinancials.com</a>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="officeTimingsOuter">
                    Office Timings: 07:00AM CST to 10:00PM CST
                    <p class="tax-free">Get TY2024 Tax Estimates for FREE</p>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="loginRegisterBtnOuter">
                    <ul>
                        <li><a href="/mytax/#/login" class="btn btn-sm btn-warning siteGreen">Login</a></li>
                        <li><a href="/mytax/#/register" class="btn btn-sm btn-warning siteBlue">Register</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
